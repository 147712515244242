import React, {Component} from 'react'
import bckgr from '../assets/kalyaz-fon.jpg'
import {Col} from 'react-bootstrap'
import razr from '../assets/razrab.jpg'
import razback from '../assets/razbacksh.jpg'

export default class Development extends Component {
    render() {
        return (
            <>
                <div class="back-photo-container-razrabotka">
                    <img
                        className="d-block w-100"
                        class='back-photo-razrabotka'
                        src={bckgr}
                        alt="antennadev"
                    />
                    <img
                        className="d-block w-100"
                        class='back-photo-razrabotka'
                        src={razback}
                        alt="antennadev"
                    />
                </div>
                <div className='text-razrab'>
                    <Col sm={6}>
                        <div>
                            <img
                                className="d-block w-100"
                                class='photo-razrabotka'
                                src={razr}
                                alt="antennadev"
                            />
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div>С 2015 года в Группе компаний Спецмонтаж отдельно выделено направление по разработке, проектированию и изготовлению системы управления антенной системы.
                        <p/>В настоящее время Общество с ограниченной ответственностью «Центр антенных технологий «Спецмонтаж» (ООО «ЦАТ Спецмонтаж») разработан абсолютно универсальный многодвигательный привод с возможностью выбора люфта.
                        <p/>Основные преимущества:</div>  
                        <p/>• основано на модульной схеме;
                        <br/>• индивидуальный подбор состава, мощности и количества приводов;
                        <br/>• конструктивные и эксплуатационные характеристики (жесткость конструкции, точность наведения, надежность, простота ремонта и обслуживания, гибкая система выборки люфта);
                        <br/>• использование российских комплектующих (импортозамещение);
                        <br/>• применение асинхронных двигателей (температурный режим до -50С°).
                        <p/>Используя данные наработки, Группа компаний Спецмонтаж выполняет модернизацию существующих антенных систем с помощью замены двигателей постоянного тока на асинхронные двигатели. Одновременно с этим проводится модернизация силовой части привода путем исключения всех «лишних» элементов, таких как редукторы переключения скоростей, дифференциальные редукторы, механизмы выбора мертвого хода. В связи с этим система зашторивания исключается из антенной системы.
                        <p/>На базе разработанной и зарекомендованной системы управления на асинхронных двигателях разработана собственная антенная система (Антенная система АС-5Т), имеющая следующие основные характеристики:
                        <p/>Диаметр основного зеркала:<strong>5 м</strong>
                        <br/>Тип ОПУ: <strong>2-х угломестный</strong> 
                        <br/>Диапазон наведения по осям: <strong>±180°</strong> 
                        <br/>Максимальная скорость наведения, <strong>9°</strong>  
                        <br/>Температурный диапазон работы: <strong>-40…+50 °C</strong>
                        <br/>Максимальная скорость ветра при работе: <strong>25 м/с</strong> 
                        <br/>Суммарная ошибка наведения:<strong> угл. сек не более 30*</strong>
                        <p/>Основными направлениями деятельности ООО «ЦАТ Спецмонтаж" являются:
                        <p/>• разработка, изготовление, модернизация антенных систем и их составных частей, на базе зеркальных и многолучевых антенн;
                        <br/>• создание систем управления и наведения антенн;
                        <br/>• выполнение НИОКР в области систем спутниковой связи и антенной техники в необходимых диапазонах волн;
                        <br/>• осуществление инновационной деятельности, в том числе разработка, производство и реализация наукоёмких технологий и продукции.
                    </Col>
                </div>
            </>
        )
    }
}