import React, {Component} from 'react'
import historyph from '../assets/historypageback.jpg'
import historyph2 from '../assets/tna1500.jpg'

export default class Development extends Component {
    render() {
        return (
            <body class="histpagebody">
                <div class="back-photo-container-razrabotka">
                    <img
                        className="d-block w-100"
                        class='back-photo-razrabotka'
                        src={historyph}
                        alt="background of work page"
                    />
                    <img
                        className="d-block w-100"
                        class='back-photo-razrabotka'
                        src={historyph2}
                        alt="background of work page"
                    />
                </div>
                <div className='text-history'>
                    С 1993 года компаниями, входящими в Группу компаний Спецмонтаж, был выполнен огромный объем работ по монтажу и техническому обслуживанию, ремонтно-восстановительным работам, а также модернизации. Более 150-ти антенных систем разных классов (диаметром зеркальной системы от 3,5 до 60 метров) были смонтированы или модернизированы, а затем сданы в эксплуатацию.<br/>
                    <br/>Нашими сотрудниками выполнялась операция по транспортировке ТНА-82 и ТНА-57И в г. Анадырь, а также в неблагоприятных метеоусловиях севера был выполнен монтаж данных комплексов.<br/>
                    <br/>С начала 2000-х годов, сотрудники Группы компаний Спецмонтаж постоянно принимают участие в работах по монтажу, техническому обслуживанию, модернизации и ремонтно-восстановительных работах на антенных комплексах, находящихся на территориях космодромов «Байконур», «Плесецк», «Восточный».<br/>
                    <br/>Совместно с заказчиками было проведено техническое обслуживание и усовершенствованы более десятка систем, таких как ТНА-57, АС К-529 (К-529А) из состава ППК «Аврора», К-526 из состава ПТК «Орион», обеспечивающих запуск и прием информации в момент старта, в период полета и выхода космических аппаратов на заданную орбиту.<br/>
                    <br/>Одним из направлений работ Группы компаний Спецмонтаж является участие в ремонте и модернизации изделий ВМФ МО России.<br/>
                    <br/>Особое внимание заслуживает выполнение работ по монтажу многолучевой антенно-приемной установки (МАПУ) «Отличие», которая позволяет осуществлять одновременный прием сигналов в трех диапазонах частот с любой поляризацией, в 10-ти лучах. Она заменяет собой 10 однолучевых антенных систем, с диаметром рефлектора 12 метров, при этом дает 4-х кратный выигрыш по площади размещения. Реализованные в установке научно-технические решения по формированию многолучевого приема с высокой эффективностью абсолютно уникальны и не имеют мировых аналогов.<br/>
                    <br/>МАПУ является многоплановым радиотехническим и инженерным изделием, в создании которого принимали участие более 10 предприятий России.<br/>
                    <br/>Сотрудники Группы компаний Спецмонтаж провели монтажные и пуско-наладочные работы как конструктивно-механической системы, так и радиотехнической части МАПУ «Отличие».<br/>
                    <br/>Приглашение для проведения монтажа металлоконструкций, работ по электромонтажу, установке и регулировке оборудования 3-х антенных систем РТФ-32, образующих единый радиоинтерферометрический комплекс «Квазар-КВО» — являющийся постоянно действующей национальной радиоинтерферометрической сетью со сверхдлинными базами (РСДБ), является высокой оценкой профессионализма специалистов Группы компаний Спецмонтаж.<br/>
                    <br/>Участие в работе по подготовке радиотелескопов ТНА-1500 «Калязин» и ТНА-1500 «Медвежьи Озёра» к работе по международной программе «Экзо Марс», предоставило возможность показать колоссальный технический потенциал сотрудников.<br/>
                    <br/>Одной из самых непростых и успешно решенных задач, был ремонт механизмов приводов наведения. Приводы наведения радиотелескопа ТНА-1500 имеют в своём составе силовые редукторы массой более 40 тонн. Специалисты Группы компаний Спецмонтаж разработали и реализовали технологию ремонта всех механизмов кинематической цепи, внутри помещений антенного комплекса, без демонтажа и отправки сборок на предприятие - изготовитель.<br/>
                    <br/>Совместная работа Группы компаний Спецмонтаж, с одной из ведущих фирм мира по изготовлению антенных систем VERTEX ANTENNENTECHNIK GmbH (Германия), получила высокую оценку иностранного партнера и приглашение участвовать в монтажных работах во многих уголках мира.<br/>
                    <br/>В Группе компаний Спецмонтаж работают выпускники лучших ВУЗов России: МЭИ, МАИ, МГТУ им. Баумана, ТулГУ, кандидаты наук. Возраст сотрудников компании от 30 лет до 70. <br/>
                    <br/>Огромный опыт работы, высококвалифицированный состав специалистов, сформировавшиеся традиции качества, позволяют Группе компаний Спецмонтаж решать сложные технические задачи и позиционировать себя как ведущую организацию в России по монтажу, ремонту и модернизации малых и крупноапертурных антенных комплексов.<br/>
                    <br/>Работа Группы компаний Спецмонтаж упомянута Президентом РФ, как высокоэффективная, а сотрудники отмечены Государственными наградами и благодарностями.<br/>
                    <br/>В 2005 году президент Российской Федерации вручил благодарственное письмо за работу участников проекта по созданию радиоинтерферометрического комплекса «Квазар-КВО», поблагодарил за большой вклад в области астрономии и высокую эффективность коллективной работы.<br/>
                    <br/>В 2015 году одна из компаний группы  Общество с ограниченной ответственностью «Специальный монтаж средств связи» (ООО «Спецмонтаж») стала лауреатом ежегодной национальной премии «Компания №1» и удостоилась почетного звания «Надежный поставщик продукции и услуг».<br/>
                </div>
            </body>
        )
    }
}